import { Navigate } from "react-router-dom";

import { DefaultLayout } from "../../../layouts/DefaultLayout";
import { AdvicePage } from "../../../pages/EmissionReport/AdvicePage/AdvicePage";
import { EmissionsPage } from "../../../pages/EmissionReport/EmissionsPage/EmissionsPage";
import { KnowledgeBasePage } from "../../../pages/KnowledgeBasePage/KnowledgeBasePage";
import { LocationsPage } from "../../../pages/LocationsPage/LocationsPage";
import { OverviewPage } from "../../../pages/OverviewPage/OverviewPage";
import { ReportsPage } from "../../../pages/Reports/ReportsPage";
import { ScenariosPage } from "../../../pages/Scenarios/ScenariosPage/ScenariosPage";
import { ScenarioCalculatorView } from "../../../pages/Scenarios/ScenariosPage/ScenarioTab/ScenarioCalculatorView/ScenarioCalculatorView";
import { PaymentHistoryPage } from "../../../pages/Settings/PaymentHistoryPage/PaymentHistoryPage";
import { SettingsCompanyPage } from "../../../pages/Settings/SettingsCompanyPage/SettingsCompanyPage";
import { SettingsLocationsPage } from "../../../pages/Settings/SettingsLocationsPage/SettingsLocationsPage";
import { SettingsUsersPage } from "../../../pages/Settings/SettingsUsersPage/SettingsUsersPage";
import { TermsAndConditionsPage } from "../../../pages/Settings/TermsAndConditionsPage/TermsAndConditionsPage";
import { UserProfilePage } from "../../../pages/UserProfilePage/UserProfilePage";
import { ValueChainPage } from "../../../pages/ValueChainPage/ValueChainPage";
import { CreateReportPage } from "../../../pages/Reports/CreateReportPage/CreateReportPage.component";
import { ScenarioTab } from "../../../pages/Scenarios/ScenariosPage/ScenarioTab/ScenarioTab.component";
import { SimulationPreviewPage } from "../../../pages/Scenarios/ScenariosPage/SimulationsTab/SimulationPreview/SimulationPreview.component";
import { SimulationsTab } from "../../../pages/Scenarios/ScenariosPage/SimulationsTab/SimulationsTab.component";
import { ProtectedRoute } from "../ProtectedRoute/ProtectedRoute.component";
import { PrivateRoutesController } from "../RouteControllers/PrivateRoutesController.component";

import { RouteConfig } from "./routes.config";

export const privateRoutes = {
  path: "/*",
  element: <PrivateRoutesController />,
  children: [
    {
      path: "*",
      element: <DefaultLayout />,
      children: [
        { path: RouteConfig.DASHBOARD.path, element: <OverviewPage />, index: true },
        { path: RouteConfig.LOCATIONS.path, element: <LocationsPage /> },
        { 
          path: RouteConfig.EXPLORER.path,
          children: [
            { index: true, element: <EmissionsPage /> },
            { path: RouteConfig.EXPLORER_ADVICE.path, element: <AdvicePage /> }
          ]
        },
        {
          path: RouteConfig.SETTINGS.path,
          element: <ProtectedRoute />,
          children: [
            { index: true, element: <SettingsCompanyPage /> },
            { path: RouteConfig.SETTINGS_LOCATIONS.path, element: <SettingsLocationsPage /> },
            { path: RouteConfig.SETTINGS_USERS.path, element: <SettingsUsersPage /> },
            { path: RouteConfig.SETTINGS_PAYMENT_HISTORY.path, element: <PaymentHistoryPage /> },
            { path: RouteConfig.SETTINGS_TERMS.path, element: <TermsAndConditionsPage /> }
          ]
        },
        { path: RouteConfig.REPORTS.path, element: <ReportsPage /> },
        { path: RouteConfig.REPORTS_CREATE.path, element: <CreateReportPage /> },
        {
          path: RouteConfig.SCENARIOS.path,
          element: <ScenariosPage />,
          children: [
            {index: true, element: <ScenarioTab />},
            {path: RouteConfig.SCENARIOS_SIMULATIONS.path, element: <SimulationsTab />},
            {path: RouteConfig.SCENARIOS_SIMULATIONS_PREVIEW.path, element: <SimulationPreviewPage />},
            {path: RouteConfig.SCENARIOS_CALCULATOR.path, element: <ScenarioCalculatorView />}
          ]
        },
        {path: RouteConfig.VALUE_CHAIN.path, element: <ValueChainPage />},
        {path: RouteConfig.KNOWLEDGE_BASE.path, element: <KnowledgeBasePage />},
        {path: RouteConfig.PROFILE.path, element: <UserProfilePage />},

        {path: "*", element: <Navigate replace to="/"/>},
      ],
    },
  ]
}