import { createBrowserRouter,Navigate } from "react-router-dom";

import { SessionController } from "../RouteControllers/SessionController";

import { privateRoutes } from "./privateRoutes";
import { publicRoutes } from "./publicRoutes";

export const router = createBrowserRouter([
  {
    path: "/*",
    element: <SessionController />,
    children: [
      publicRoutes,
      privateRoutes,
      { path: "*", element: <Navigate replace to="/"/> },
    ],
  },
]);
