import { Navigate } from "react-router-dom";

import AuthPageInvitation from "../../../pages/AuthPageInvitation";
import { ForgotPasswordPage } from "../../../pages/AuthPages/ForgotPasswordPage/ForgotPasswordPage";
import { LoginPage } from "../../../pages/AuthPages/LoginPage/LoginPage";
import { NewPasswordPage } from "../../../pages/AuthPages/NewPasswordPage/NewPasswordPage";
import { PublicLayout } from "../../../layouts/PublicLayout/PublicLayout.component";
import { PublicRoutesController } from "../RouteControllers/PublicRoutesController.component";

import { RouteConfig } from "./routes.config";

export const publicRoutes = {
  element: <PublicRoutesController />,
  children: [
    {
      element: <PublicLayout />,
      children: [
        { path: RouteConfig.LOGIN.path, element: <LoginPage /> },
        { path: RouteConfig.FORGOT_PASSWORD.path, element: <ForgotPasswordPage /> },
        { path: RouteConfig.INVITATION.path, element: <AuthPageInvitation /> },
        { path: RouteConfig.NEW_PASSWORD.path, element: <NewPasswordPage /> },
        { path: "*", element: <Navigate to={RouteConfig.LOGIN.fullPath} replace /> },
      ]
    },
  ]
}