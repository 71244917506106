import { useMemo } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useAppSelector } from "../../../hooks/storeHooks";
import { selectToken, selectUserData } from "../../../store/auth/auth.selectors";

export const PrivateRoutesController = () => {
  const userData = useAppSelector(selectUserData)
  const accessToken = useAppSelector(selectToken)
  const hasAccess = useMemo(() => accessToken && !userData.forcePasswordChange, [userData]);

  if (accessToken && userData.forcePasswordChange) {
    return <Navigate to="/new-password" replace />
  }

  return hasAccess ? <Outlet /> : <Navigate to="/login" replace />
}